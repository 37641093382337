
<template>
  <div>
    <div class="commom_title">
      <a-row type="flex" justify="space-between">
        <a-col :span="8">
          <a-row>
            <a-col :span="2">
              <div class="commom_line"></div>
            </a-col>
            <a-col :span="6">购物车</a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
    <div class="shopping-cart">
      <div class="products">
        <!-- 购物车列表的头部信息栏 -->
        <div class="header">
          <div class="header-container">
            <a-row>
              <a-col :span="4">
                <label class="all-selected-title header-title">
                  <input type="checkbox" name @click="selectAll(shops)" v-model="isSelectedAll" />
                  <span>全选</span>
                </label>
              </a-col>
              <a-col :span="8">
                <span class="header-title">商品</span>
              </a-col>
              <a-col :span="3">
                <span class="header-title">单价</span>
              </a-col>
              <a-col :span="3">
                <span class="header-title">数量</span>
              </a-col>
              <a-col :span="3">
                <span class="header-title">小计</span>
              </a-col>
              <a-col :span="2">
                <span class="header-title">操作</span>
              </a-col>
            </a-row>
          </div>
        </div>
        <!-- 购物车中每个商家的列表 -->
        <ul class="shop-list">
          <!-- 以商家为列表，同一商家的商品放进同一个商家li里面 -->
          <li v-for="shop in shops" :key="shop.supplierId">
            <!-- 商家信息 -->
            <div class="shop-info">
              <input
                type="checkbox"
                class="shop_check"
                @click="selectAll(shop)"
                v-model="isShopSelectedAll[shops.indexOf(shop)]"
              />
              <span class="shop-name">{{ shop.supplierName }}</span>
            </div>
            <!-- 每个商家内的商品列表 -->
            <ul class="product-list">
              <li v-for="product in shop.products" :key="product.cartId">
                <a-row style="display: flex; align-items: center;">
                  <a-col :span="1">
                    <input type="checkbox" class="product_check" v-model="product.isSelected" />
                  </a-col>
                  <a-col :span="10">
                    <a-row>
                      <a-col :span="5">
                        <div class="product_image">
                          <img :src="getPic(product.pic)" alt />
                        </div>
                      </a-col>
                      <a-col :span="18">
                        <div class="product_name">{{ product.productName }}</div>
                        <div class="header-text">规格：{{ product.specifications }}</div>
                        <div class="header-text">型号：{{ product.model }}</div>
                        <div class="header-text">计量单位：{{ product.unit }}</div>
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :span="3" style="margin-left:52px">
                    <div class="header-text">￥{{ product.price }}</div>
                  </a-col>
                  <a-col :span="3">
                    <a-input-number class="number" :min="1" v-model="product.num" />
                  </a-col>
                  <a-col :span="3">
                    <div
                      class="header-text"
                      style="color: #FF4848"
                    >￥{{ accMul(product.num,product.price) }}</div>
                  </a-col>
                  <a-col :span="2">
                    <a-popconfirm
                      title="确定删除当前数据?"
                      ok-text="确定"
                      cancel-text="取消"
                      @confirm="confirm"
                      @cancel="cancel"
                    >
                      <a-button type="link" class="delete" @click="getItem(product)">删除</a-button>
                    </a-popconfirm>
                  </a-col>
                </a-row>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="shopping_car_bottom">
        <a-row type="flex" justify="end">
          <a-col style="margin-right:30px">
            <div class="shopping_bottom_price">
              总价：
              <span style="color: #FF4848;font-size: 20px">￥{{ allTotal }}</span>
            </div>
            <!-- <div class="shopping_bottom_text">运费由买方支付</div> -->
          </a-col>
          <a-col :span="3">
            <a-button type="primary" class="shopping_car_btn" @click="Sure">确认订单</a-button>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
import { employmentCarTotal, employmentMallDelete, employmentMallList } from '@/api/materialShop'

export default {
  name: 'ShoppingCart',
  inject: ['reload'],
  data () {
    return {
      activeIndex: 0,
      scrollBottom: 0,
      shops: [],
      price: 0,
      chooseProduct: [],
      obj: {}
      // inputProducts,
    }
  },
  computed: {
    // 购物车中的商品是否全选
    isSelectedAll: {
      get () {
        for (var i = 0; i < this.shops.length; i++) {
          if (!this.isShopSelectedAll[i]) {
            return false
          }
        }
        return true
      },
      // 这里要加一个空的setter，因为用v-model绑定时会报错
      set () {}
    },
    // 店铺中的商品是否全选
    isShopSelectedAll: function () {
      var tempArr = []
      for (var i = 0; i < this.shops.length; i++) {
        tempArr[i] = true
        var products = this.shops[i].products
        for (var j = 0; j < products.length; j++) {
          if (!products[j].isSelected) {
            tempArr[i] = false
            break
          }
        }
      }
      return tempArr
    },
    allTotal () {
      return this.shops.reduce((total, item) => {
        const { products = [] } = item
        const data = products.filter(i => i.isSelected)
        return data.reduce((t, i) => {
          return this.accAdd(t, this.accMul(i.price, i.num))
        }, total)
      }, 0)
    }
  },

  mounted () {
    console.log(Number)
    this.calScrollBottom()
    this.autoHideSummaryBar()
    this.init()
  },
  methods: {
    getItem (item) {
      this.obj = item
    },
    confirm () {
      const params = {
        id: this.obj.cartId
      }
      employmentMallDelete(params)
        .then(res => {
          if (res.success) {
            this.$message.success('删除成功')
            this.init()
          } else {
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    cancel (e) {
      console.log(e)
      // this.$message.error('');
    },

    Sure () {
      const checkList = this.shops.reduce((arr, item) => {
        const { products = [] } = item
        const data = products.filter(i => i.isSelected)
        if (data.length) {
          return [
            ...arr,
            {
              ...item,
              products: data
            }
          ]
        }
        return arr
      }, [])
      if (checkList.length == 0) {
        this.$message.error('请至少选择一条数据')
        return
      }
      this.reload()
      this.$router.push({
        path: '/supplies/supplies-order',
        query: { totalPrice: this.allTotal, data: JSON.stringify(checkList), isCar: true }
      })
    },

    getTotalPrice (nums, productIds) {
      const params = {
        nums: nums,
        productIds: productIds
      }

      employmentCarTotal(params)
        .then(res => {
          this.price = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    init () {
      employmentMallList()
        .then(res => {
          for (var i = 0; i < res.data.length; i++) {
            for (var j = 0; j < res.data[i].products.length; j++) {
              res.data[i].products[j].isSelected = false
            }
          }
          this.shops = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    // 全选购物车或者单个店家
    selectAll: function (all) {
      const nums = []
      const productIds = []
      if (all instanceof Array) {
        var bool = !this.isSelectedAll
        // var bool = false;\
        for (var i = 0; i < all.length; i++) {
          var products = all[i].products
          for (var j = 0; j < products.length; j++) {
            products[j].isSelected = bool
          }
        }
      } else {
        var index = this.shops.indexOf(all)
        var bool = !this.isShopSelectedAll[index]
        for (var i = 0; i < all.products.length; i++) {
          all.products[i].isSelected = bool
        }
      }
    },

    // 计算滚动条移动条下方与屏幕地面的距离；
    calScrollBottom: function () {
      var scrollTop = document.documentElement.scrollTop
      // 滚动条与顶部的距离，可理解为滚动条移动条部分的顶部与屏幕顶部之间的距离；
      var clientHeight = document.documentElement.clientHeight
      // 屏幕可视高度，可理解为滚动条移动条部分的长度；
      var scrollHeight = document.documentElement.scrollHeight
      // 滚动条可滚动的总高度，可理解为整条滚动条的长度；
      this.scrollBottom = scrollHeight - clientHeight - scrollTop
    },
    // 自动检测滚动条下拉距离，隐藏金额汇总栏
    autoHideSummaryBar: function () {
      var calScrollBottom = this.calScrollBottom
      var fixHeaderAgain = this.fixHeaderAgain
      window.onscroll = function () {
        fixHeaderAgain()
        calScrollBottom()
      }
    },
    // 解决与固定表头的事件冲突
    fixHeaderAgain: function () {
      this.$emit('fix-header')
    },
    getPic (pic) {
      return pic ? pic.split(',')[0] : ''
    }
  }
}
</script>

<style scoped lang="less">
@import url('./index');
@import url('../../../common/style');
.product-list {
  margin-left: 36px;
}
</style>
